import React, { Component } from "react";
import {
    Row,
    Col,
    Avatar,
    Card,
    Tabs,
    Button,
    Radio,
    notification,
    Table,
    Space,
    Modal,
    Form,
    Input,
} from "antd";
import { userService } from "../../services";

export class FabNetUsers extends Component {
    columns = [
        {
            title: 'First Name',
            dataIndex: 'firstName',
            render: (text, row, index) => <a>{text}</a>
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            render: (text, row, index) => text,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            render: (text, row, index) => text,
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <a>Edit</a>
                    <a>Delete</a>
                </Space>
            ),
        },
    ];
    userForm = null;

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            modals: {
                createUser: false
            }
        };
    }

    componentDidMount() {
        this.fetchUsers();
    }

    fetchUsers = () => {
        userService.getFabNetUsers()
            .then(result => {
                if (result.isSuccess) {
                    let users = result.dto;
                    users.forEach((x, i) => x.key = i);
                    this.setState({ users });
                }
                else {
                    // this.props.toastManager.add(result.message, { appearance: 'error', autoDismiss: true });
                }
            });
    }

    onCreateUser = () => {
        let modals = { ...this.state.modals };
        modals.createUser = true;
        this.setState({ modals });
    }

    createUser = (values) => {
        const user = {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phoneNumber: values.phoneNumber,
            address: {
                street: values.street,
                houseNumber: values.houseNumber,
                city: values.city,
                state_District: values.state_District,
                country: values.country
            }
        };

        userService.createAdminUser(user)
            .then(result => {
                if (!result.isSuccess) {

                } else {
                    this.userForm.resetFields();
                    this.userForm = null;
                    let modals = { ...this.state.modals };
                    modals.createUser = false;
                    this.setState({ modals }, () => this.fetchUsers());
                }
            });
    }

    render() {
        const { modals, users } = this.state;

        return (
            <div>
                <h3>FabNet Admin Users</h3>
                <Button type="primary" size="small" style={{ marginTop: 10, marginBottom: 10 }} onClick={this.onCreateUser}>Create User</Button>
                <Table size='small' columns={this.columns} dataSource={users} bordered />

                {modals.createUser &&
                    <Modal
                        title="Create New User"
                        centered
                        maskClosable={false}
                        visible={modals.createUser}
                        keyboard={false}
                        onOk={() => {
                            this.userForm
                                .validateFields()
                                .then(values => {
                                    this.createUser(values);
                                })
                                .catch(info => {
                                    console.log('Validate Failed:', info);
                                });
                        }}
                        onCancel={() => {
                            let modals = { ...this.state.modals };
                            modals.createUser = false;
                            this.setState({ modals });
                        }}
                    >
                        <Form
                            form={this.userForm}
                            ref={_ref => this.userForm = _ref}
                            name="create_user_modal"
                            labelCol={{ span: 6 }}
                            initialValues={{ country: "Sri Lanka" }}
                        >
                            <Form.Item
                                label="First Name"
                                name="firstName"
                                rules={[{ required: true, message: 'First Name is required' }]}
                            >
                                <Input />
                            </Form.Item>
                            <Form.Item name="lastName" label="Last Name" rules={[{ required: true, message: 'Last Name is required' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="email" label="Email Address" rules={[{ required: true, message: 'Email Address is required' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="phoneNumber" label="Phone Number">
                                <Input />
                            </Form.Item>
                            <Form.Item name="houseNumber" label="House Number" style={{ marginTop: 50 }}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="street" label="Street Address">
                                <Input />
                            </Form.Item>
                            <Form.Item name="city" label="City" rules={[{ required: true, message: 'City is required' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="state_District" label="District" rules={[{ required: true, message: 'District is required' }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="country" label="Country" rules={[{ required: true, message: 'Country is required' }]}>
                                <Input />
                            </Form.Item>
                        </Form>
                    </Modal>
                }
            </div>
        );
    }
}

export default FabNetUsers;