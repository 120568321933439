import axios from '../config/API';
import { trackPromise } from 'react-promise-tracker';
import { environment } from "../environments/environment";

export const joinRequestsService = {
    acceptRequest,
    rejectRequest,
    getAllRequests,
};

async function acceptRequest(requestId) {
    try {

        let responce = await trackPromise(axios.put('/CompanyJoinRequests/' + requestId + '/accept'));
        return ({ isSuccess: true, data: responce.data, message: responce.message });
    }
    catch (err) {
        console.error(err);
        return { isSuccess: false, message: "Unable to accept request" };
    }
}

async function rejectRequest(requestId) {
    try {
        let responce = await trackPromise(axios.put('/CompanyJoinRequests/' + requestId + '/reject'));
        return ({ isSuccess: true, data: responce.data, message: responce.message });
    }
    catch (err) {
        console.error(err);
        return { isSuccess: false, message: "Unable to reject request" };
    }
}
async function getAllRequests() {
    try {
        let responce = await trackPromise(axios.get('/CompanyJoinRequests/getall'));
        return ({ isSuccess: true, data: responce.data, message: responce.message });
    }
    catch (err) {
        console.error(err);
        return { isSuccess: false, message: "Unable to get requestsw" };
    }
}
