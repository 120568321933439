import React, { useState } from "react";
import { Form, Input, Button, Space, Table, Typography } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { supplierService } from "../../services/supplierService";
const { Title } = Typography;

const ProductServiceCreation = ({ companyId, type, data }) => {
    console.log(type, data, companyId)
    const onFinish = values => {
        console.log('Received values of form:', values);
    };
    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title'
        },
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },

        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <a>Edit</a>
                    <a>Delete</a>
                </Space>
            ),
        },
    ];
    const rows = new Array;
    if (data) {

        data.forEach(d => {
            rows.push({
                key: d.id,
                title: d.title,
                category: d.category,
                description: d.description
            })
        });
    }
    return (
        <div>
            <Title level={4}>{type}</Title>
            <Table columns={columns} dataSource={rows} />
        </div>
    );
};

ProductServiceCreation.defaultProps = {
    valueProperty: "companyId",
    textProperty: "type",
};

export default ProductServiceCreation;
