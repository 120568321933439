import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';
import 'antd/dist/antd.css';
import './style.css';

const ImageUploader = ({
    Url,
    Action
}) => {
    let files = [];
    if (Url != null) {
        files = [{
            uid: '-1',
            url: Url,
        }]
    }
    const [fileList, setFileList] = useState(files);

    const onChange = ({ fileList: newFileList }) => {
        // debugger
        if (newFileList.length > 0) {
            newFileList[0].status = 'done'
        }
        setFileList(newFileList);
    };

    const onPreview = async (file) => {
        let src = file.url;
        if (!src) {
            src = await new Promise((resolve) => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };
    return (
        <ImgCrop grid>
            <Upload
                action={Action}
                listType="picture-card"
                fileList={fileList}
                // showUploadList={false}
                // method="put"
                onChange={onChange}
                onPreview={onPreview}
                className="avatar-uploader"
            >
                {fileList.length < 1 && '+ Upload'}
            </Upload>
        </ImgCrop>
    );
};

// ImageUploader.defaultProps = {
//     textProperty: "Url",
//     valueProperty: "UserId",
// };

export default ImageUploader;
