import axios from '../config/API';
import { trackPromise } from 'react-promise-tracker';

export const supplierService = {
    fetchSuppliers,
    submitSupplier,
    deleteSupplier,
    fetchSupplier,
    updateSupplier,
    inviteUser,
    addCertification,
    getIndustryTypes,
    addService,
    addProduct,
    getProductCategories,
    uploadLogo
    // getSupplierAddresses,
    // submitNewSupplierAddress,
};

export async function fetchSupplier(id) {
    try {
        const result = await trackPromise(axios.get(`/suppliers/${id}`));
        if (!result.data.isSuccess) return null;
        return result.data;
    }
    catch (err) {
        console.log(err);
        return { isSuccess: false, message: "Unable to get Supplier" };
    }
}


async function fetchSuppliers() {
    try {
        const result = await trackPromise(axios.get('/suppliers'));
        return result.data;
    }
    catch (err) {
        return { isSuccess: false, message: "Unable to get suppliers" };
    }
}

async function uploadLogo(file, supplierId) {
    console.log(file, supplierId)
    try {
        var bodyFormData = new FormData();
        bodyFormData.append('file', file);
        return (await trackPromise(axios.post("/suppliers/" + supplierId + "/logo", bodyFormData))).data;
    }
    catch (err) {
        console.log(err);
        return { isSuccess: false, message: "Unable to create supplier" };
    }
}

async function submitSupplier(supplier) {
    console.log(supplier)
    try {
        let responce = await trackPromise(axios.post('/suppliers', supplier))
        return (responce.data);
    }
    catch (err) {
        console.log(err);
        return { isSuccess: false, message: "Unable to create supplier" };
    }
}

async function updateSupplier(id, supplier) {
    console.log(id, supplier)
    try {
        return (await trackPromise(axios.put(`/suppliers/${id}`, supplier))).data;
    }
    catch (err) {
        console.log(err);
        return { isSuccess: false, message: "Unable to update supplier" };
    }
}

async function deleteSupplier(id) {
    try {
        return (await trackPromise(axios.delete(`/suppliers/${id}`))).data;
    }
    catch (err) {
        console.log(err);
        return { isSuccess: false, message: "Unable to delete customer" };
    }
}

async function inviteUser(customerId, email) {
    try {
        return (await trackPromise(axios.post(`/customers/${customerId}/users/invite/${email}`))).data;
    }
    catch (err) {
        console.log(err);
        return { isSuccess: false, message: "Unable to send invite" };
    }
}

async function addCertification(id, data) {
    try {
        const result = await trackPromise(axios.put(`/suppliers/${id}/certifications`, data));
        return result.data;
    }
    catch (err) {
        console.log(err);
        return { isSuccess: false, message: "Unable to add certificate" };
    }
}

async function getIndustryTypes() {
    try {
        const result = await trackPromise(axios.get(`/suppliers/industries`));
        return result.data;
    }
    catch (err) {
        console.log(err);
        return { isSuccess: false, message: "Unable to get industry types" };
    }
}

async function addService(id, data) {
    try {
        const result = await trackPromise(axios.put(`/suppliers/${id}/services`, data));
        return result.data;
    }
    catch (err) {
        console.log(err);
        return { isSuccess: false, message: "Unable to add service" };
    }
}

async function getProductCategories() {
    try {
        const result = await trackPromise(axios.get(`/suppliers/products/categories`));
        return result.data;
    }
    catch (err) {
        console.log(err);
        return { isSuccess: false, message: "Unable to get product categories" };
    }
}

async function addProduct(id, data) {
    try {
        const result = await trackPromise(axios.put(`/suppliers/${id}/products`, data));
        return result.data;
    }
    catch (err) {
        console.log(err);
        return { isSuccess: false, message: "Unable to add product" };
    }
}