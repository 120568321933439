import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Spin } from "antd";
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
import axios from './config/API';
import { authService } from "./services";
import { ToastContainer } from "react-toastr";
import { ToastProvider } from 'react-toast-notifications';

let container;

let token = authService.currentUserValue?.token || '';
authService.currentUser.subscribe(value => { if (!!value) { token = value.token } });

axios.interceptors.request.use((request) => {
  request.headers["Authorization"] = "Bearer " + token;
  request.headers["Content-Type"] = "application/json";
  return request;
});

axios.interceptors.response.use(function (response) {
  return response;
}, async function (error) {
  const originalRequest = error.config;
  if (401 === error.response.status) {
    authService.logout();
    window.location.replace('/login');
    // const refreshToken = authService.currentUserValue.refreshToken;
    // const token = authService.currentUserValue.token;
    // if (!refreshToken || !token) {
    //   authService.logout();
    //   window.location.replace('/login');
    // }

    // try {
    //   const result = await trackPromise(axios.post('/auth/refresh',
    //     { token: token, refreshToken: refreshToken }));

    //   if (!result.data.isSuccess) {
    //     this.setState({ error: result.data.message, isError: true });
    //     authService.logout();
    //     window.location.replace('/login');
    //   }
    //   else {
    //     localStorage.setItem('currentUser', JSON.stringify(result.data.dto))
    //     originalRequest.headers["Authorization"] = "Bearer " + result.data.dto.token;
    //     originalRequest.headers["Content-Type"] = 'application/json';

    //     return axios(originalRequest);
    //   }
    // }
    // catch(err) {
    //   authService.logout();
    //   window.location.replace('/login');
    // }
  } else {
    return Promise.reject(error);
  }
});

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return (
    promiseInProgress && (
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: "39000",
        }}
      >
        <Spin size='large' tip="Loading..." height={100} width={100} />
      </div>
    )
  );
};

ReactDOM.render(
  <ToastProvider>
    <LoadingIndicator />
    <App />
    <ToastContainer
      ref={ref => container = ref}
      className="toast-top-right"
    />
  </ToastProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

export const showToast = (message, type) => {
  if (type === 'error') container.error(message, { closeButton: true });
  else container.success(message, { closeButton: true });
}