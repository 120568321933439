import React, { useState } from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import { Upload } from "antd";
import ImgCrop from "antd-img-crop";

import { companyService } from "../../services";

const LogoUpload = ({ supplierId, logo }) => {
    console.log(logo);
    const [fileList, setFileList] = useState([
        {
            uid: "-1",
            name: "file",
            status: "done",
            url: logo
        }
    ]);

    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow.document.write(image.outerHTML);
    };

    const uploadFile = (file) => {
        console.log(file, supplierId)
        companyService.uploadLogo(file, supplierId).then(responce => {
            console.log("Uplode responce", responce)
        }).catch(e => {
            console.log(e);
        })
    }

    return (
        <ImgCrop rotate>
            <Upload
                action={uploadFile}
                listType="picture-card"
                fileList={fileList}
                onChange={onChange}
                onPreview={onPreview}
            >
                {fileList.length < 1 && "+ Upload"}
            </Upload>
        </ImgCrop>
    );
};

export default LogoUpload;
